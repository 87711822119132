@import "~jquery-drawer/scss/drawer.scss";

.drawer {
  &-nav {
    background-color: #29221c;
    padding-top: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    .logo {
      width: 110px;
      margin: 0 auto;
    }
  }
  &-menu .active a {
    opacity: 0.5;
  }
  &-menu-item {
    color: $primary;
    padding: 0.35rem;
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }

  &--right.drawer-open .drawer-hamburger {
    right: 0;
  }
  &-hamburger-icon,
  &-hamburger-icon:before,
  &-hamburger-icon:after {
    background-color: #fff;
  }
  &-open .drawer-hamburger-icon {
    background-color: transparent !important;
  }
}

@media (min-width: 1600px) {
  .drawer {
    &-nav {
      width: 230px;
      padding-top: 2rem;
      padding-left: 2rem;
      background-color: initial;
      left: 0;
    }
    &-menu-item {
      text-align: left;
    }
    &-hamburger-icon {
      display: none;
    }
  }
}
