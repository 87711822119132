@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&family=Noto+Serif+JP:wght@300;400;500;700;900&family=Shippori+Mincho+B1:wght@600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "scss/bootstrap.scss";
@import "scss/drawer.scss";
@import "scss/animate.css";
@import "scss/fullpage.scss";

@import "scss/common.scss";
@import "scss/main.scss";
@import "scss/anime.scss";