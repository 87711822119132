html {
  scroll-behavior: smooth;
  @media (max-width: 576px) {
    font-size: 13px;
  }
}
body {
  color: #f7f7f8;
  line-height: 1.8rem;
  font-family: "Noto Sans JP", sans-serif;
  @media (max-width: 576px) {
    line-height: 1.6rem;
  }
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  opacity: 0.8;
  color: inherit;
}
button,
.btn {
  outline: none;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
}
:focus {
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
.lar{
  font-size: 1.125rem;
}
.fwb {
  font-weight: bold;
}
.fwn {
  font-weight: normal;
}
// .serif {
//   font-family: "Noto Serif JP", serif;
// }
.sans {
  font-family: "Noto Sans JP", sans-serif;
}
.ship,
.serif {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
}

.sp-logo {
  display: none;
  @media (min-width: 578px) {
    display: none !important;
  }
  position: absolute;
  top: 1rem;
  z-index: 2;
  left: 1rem;
  width: 120px;
}

.buttons {
  display: inline-block;
  border: 1px solid $primary;
  color: $primary;
  width: 200px;
  text-align: center;
  max-width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: transparent;
  transition: all 0.2s;
  position: relative;
  z-index: 2;

  &:hover {
    background-color: $primary;
    color: #333;
    opacity: 1 !important;
  }
  &:before {
    right: 0;
  }
  &:after {
    left: 0;
  }
  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    display: block;
    content: "";
    top: 0;
    width: 50%;
    height: 100%;
    background-color: transparent;
  }
  &:hover:before,
  &:hover:after {
    transition: all 0.3s;
    position: absolute;
    z-index: -1;
    display: block;
    content: "";
    width: 0;
    background-color: #333;
  }
}

@media (max-width: 576px) {
  .section {
    padding-top: 2rem;
  }
}

.icon-insta {
  position: relative;
  top: 1px;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

//Pagenation
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  position: relative;
  font-size: 1.2rem;
}
.pagination span,
.pagination a {
  display: block;
  width: auto;
  margin: 4px;
  padding: 8px;
  text-decoration: none;
  border: 1px solid $primary;
  text-align: center;
  line-height: 16px;
}
.pagination .pager {
  width: 32px;
}
.pagination a:hover,
.pagination .current {
  color: #333;
  background-color: $primary;
}
.pagination a.prev {
  margin-right: 16px;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 0.5rem;
}
.pagination a.next {
  margin-left: 16px;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 0.5rem;
}
.pagination a.first,
.pagination a.last,
.pagination span.page_num {
  display: none;
}
