$enable-responsive-font-sizes: true;
$primary: #f7f7f8;
$secondary: #3e2b2b;

@import "~bootstrap/scss/bootstrap.scss";

label {
  margin-bottom: 0;
}

.form-check-input {
  margin-top: 0.5rem;
}

.row-0 {
  margin-left: 0px;
  margin-right: 0px;

  > div {
    padding-right: 0px;
    padding-left: 0px;
  }
}
.row-10 {
  margin-left: -5px;
  margin-right: -5px;

  > div {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.row-20 {
  margin-left: -10px;
  margin-right: -10px;

  > div {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.row-30 {
  margin-left: -15px;
  margin-right: -15px;

  > div {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.row-40 {
  margin-left: -20px;
  margin-right: -20px;

  > div {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.row-70 {
  margin-left: -35px;
  margin-right: -35px;

  > div {
    padding-right: 35px;
    padding-left: 35px;
  }
}

.table-bordered th,
.table-bordered td {
  vertical-align: middle;
}
