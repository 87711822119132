.section {
  background-size: cover;
}

#secTop {
  background-image: url(../img/section01_bg.png);
  background-size: cover;
  background-position: center;
}
#secAbout {
  background-image: url(../img/section02_bg.png);
  background-size: cover;
  background-position: center;
}
#secFacility {
  background-image: url(../img/section03_bg.png);
  background-size: cover;
  background-position: center;
}
#secEvent {
  background-image: url(../img/section04_bg.png);
  background-size: cover;
  background-position: center;
}
#secArtist {
  background-image: url(../img/section05_bg.png);
  background-size: cover;
  background-position: center;
}
#secAccess {
  background-image: url(../img/section06_bg.png);
  background-size: cover;
  background-position: center;
}
#secAccess {
  background-image: url(../img/section06_bg.png);
  background-size: cover;
  background-position: center;
}
#secContact {
  background-image: url(../img/contact_bg.png);
  background-position: center;
  background-size: auto;
}
#archiveEvent {
  background-image: url(../img/archive_event-bg.png);
  background-position: center;
  background-size: auto;
}
#archiveNews {
  background-image: url(../img/archive_news-bg.png);
  background-position: center;
  background-size: auto;
}

#secTop {
  .news {
    &__items {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      align-items: center;
      justify-content: start;
      border-bottom: 1px solid $primary;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      @media (max-width: 767px) {
        border-bottom: none;
      }
    }
    &__inner {
      display: flex;
      align-items: center;
    }
    &__date {
    }
    &__cat {
      border: 1px solid $primary;
    }
    &__ttl {
      margin-bottom: 0;
      padding-top: 0.35rem;
    }
  }
}

#secFacility {
  .facility {
    &__more {
      position: absolute;
      bottom: 0;
      left: 1rem;
      @media (max-width: 576px) {
        position: static;
      }
    }
    &__img-top {
      width: 18.75rem;
      height: auto;
      margin: 0 auto;
    }
  }
}

#secEvent {
  .event {
    &__img {
      max-width: 234px;
      @media (max-width: 767px) {
        max-width: 180px;
      }
      @media (max-width: 576px) {
        max-width: 120px;
      }
    }
    &__date {
      font-size: 0.875rem;
      margin-bottom: 0;
      line-height: 1.4rem;
    }
    &__ttl {
      line-height: 1.4rem;
    }
    &__calendar {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

#secArtist,
#pageArtist {
  .artist {
    &__img {
      width: 200px;
      @media (max-width: 767px) {
        max-width: 200px;
      }
      @media (max-width: 576px) {
        width: 110px;
      }
    }
    &__part {
      margin-bottom: 0.5rem;
    }
    &__name {
      display: inline;
      font-size: 1.15rem;
    }
    &__honor {
      display: inline;
      font-size: 0.875rem;
    }
    &__desc {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    &__sns {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: -2rem;
      @media (max-width: 767px) {
        position: static;
      }
      a {
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-of-type {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
          @media (max-width: 576px) {
            width: 20px;
            height: auto;
          }
        }
      }
    }
    &__more {
      position: absolute;
      bottom: 1rem;
    }
  }
}

#secAccess {
  .map {
    &__tabs .nav-link {
      background-color: #fff;
      color: $secondary;
      border-radius: 0;
      border-right: 1px solid $secondary;
      border-left: 1px solid $secondary;
      &:first-of-type {
        border: none;
      }
      &:last-of-type {
        border: none;
      }
      &.active {
        border: none;
        background-color: $secondary;
        color: $primary;
      }
    }
    &__logo {
      width: 110px;
      @media (max-width: 576px) {
        width: 75px;
      }
    }
  }
}

#pageReserve {
  .flow {
    &:after {
      background-image: url(../img/flow_arrow.svg);
      content: "";
      right: 0rem;
      top: 45%;
      transform: translateY(-50%) rotate(225deg);
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      @media (max-width: 1199px) and (min-width: 992px) {
        display: none;
      }
      @media (max-width: 991px) {
        right: 50%;
        top: unset;
        bottom: -2.5rem;
        transform: translateY(0%) translateX(-50%) rotate(-45deg);
      }
    }
    &:last-of-type:after {
      background-image: none !important;
    }
    &__desc {
      font-size: 0.875rem;
      line-height: 1.4rem;
    }
    &__img {
      min-width: 220px;
      max-width: 220px;
      @media (max-width: 576px) {
        min-width: unset;
        max-width: 140px;
      }
    }
    &__arrow {
      position: relative;
      top: -1.5rem;
      @media (max-width: 576px) {
        top: -2.5rem;
        width: 10px;
      }
    }
  }
  .date-start {
    &:after {
      position: absolute;
      content: "～";
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        right: 50%;
        top: unset;
        transform: translateX(-50%);
      }
    }
  }
}

#pageFacility {
  .nav .active {
    background-color: $primary;
    color: $secondary;
    & img {
      display: none;
    }
  }
  .nav a {
    height: 50px;
    img {
      transition: 0.1s;
    }
  }
  .menu {
    &__inner {
      padding-bottom: 8rem;
      @media (max-width: 767px) {
        padding-bottom: 2rem;
      }
    }
    &__set {
      border: 1px solid #f7f7f8;
      display: flex;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      min-height: 120px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @media (max-width: 767px) {
        position: static;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__set__ttl {
      border-right: 1px solid #f7f7f8;
      padding-right: 2rem;
      padding-left: 2rem;
      display: flex;
      align-items: center;
    }
    &__set__content {
      padding-left: 2rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
  .note {
    &__decimal {
      list-style: decimal;
      padding-left: 1rem;
    }
    &__disc {
      list-style: disc;
      padding-left: 1rem;
    }
  }
  .table {
    font-size: 0.875rem;
  }
}

.archive {
  &__items {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid $primary;
    padding-bottom: 0.75rem;
    margin-bottom: 2rem;
  }
  &__inner {
    display: flex;
    align-items: center;
  }
  &__date {
  }
  &__cat {
    border: 1px solid $primary;
  }
  &__ttl {
    margin-bottom: 0;
    padding-top: 0.35rem;
  }
}

// Only IE
@media all and (-ms-high-contrast: none) {
  .fc .fc-button-primary{
    background-color: transparent !important;
  }
  .form-check-input{
    margin-left: 12.25rem;
  }
  .pc-logo .logo,
  .map__logo{
    display: none;
  }
}