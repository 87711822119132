@import "~fullpage.js/dist/fullpage.css";

.section.reveal .container {
  transform: translateY(+50px);
  opacity: 0;
  transition: opacity 0.6s, transform 1s;
  transition-delay: 0.4s;
}
.section.active.reveal .container {
  transform: translateY(0);
  opacity: 1;
}

.slideBar {
  @media (max-width: 1050px) {
    display: none;
  }
  position: fixed;
  right: 0;
  //   top: 117px;
  bottom: 5rem;
  width: 56px;
  &__wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__bar {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border-left: 1px solid #fff;
  }
  &__num {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
  &__active,
  &__total {
    font-size: 1rem;
    color: #fff;
    line-height: 100%;
    font-family: "Shippori Mincho B1", serif;
  }
  &__active {
    position: absolute;
    top: 0;
    left: -35px;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 25px;
  }
  &__total {
    position: absolute;
    bottom: 0;
    left: 8px;
    margin: 0;
    padding: 0;
  }
}
